import React, {CSSProperties, RefObject} from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import autobind from 'autobind-decorator';
import a11y from '@wix/wixstores-client-core/dist/es/src/assets/styles/_accessibility.scss';
import {IProduct} from '../../../types/galleryTypes';
import {ProductPriceWithGlobalProps} from './ProductPrice/ProductPrice';

import s from './ProductItem.scss';
import {ProductImage} from './ProductImage/ProductImage';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../gallery/galleryGlobalStrategy';
import {StatesButton} from 'wix-ui-tpa/StatesButton';
import addToCartButtonStylable from './AddToCartButton.st.css';

export enum DataHook {
  Root = 'product-item-root',
  Ribbon = 'product-item-ribbon',
  QuickViewButton = 'product-item-quick-view-button',
  ProductDetails = 'product-item-product-details',
  SrOnlyName = 'product-item-sr-only-name',
  Name = 'product-item-name',
  LineBetweenNameAndPrice = 'product-item-line-between-name-and-price',
  Price = 'product-item-price',
  AddToCartButton = 'product-item-add-to-cart-button',
}

export interface IProductItemProps extends IGalleryGlobalProps {
  style?: CSSProperties;
  product: IProduct;
  index: number;
  innerRef?: Function;
  disabled?: boolean;
}

interface IProductItemState {
  showHoverPreview: boolean;
}

const PREVIEW_DURATION = 1000;

@withGlobals
@autobind
export class ProductItem extends React.Component<IProductItemProps, IProductItemState> {
  private readonly productLink = React.createRef<HTMLAnchorElement>();
  public addToCartButtonRef: RefObject<StatesButton> = React.createRef();
  private readonly debouncedStopHoverPreview = _.debounce(() => {
    this.setState({showHoverPreview: false});
  }, PREVIEW_DURATION);

  constructor(props) {
    super(props);
    this.state = {showHoverPreview: false};
  }

  public focus(): void {
    this.productLink.current.focus();
  }

  private renderSrOnlyName() {
    return (
      <span data-hook={DataHook.SrOnlyName} className={a11y.srOnly}>
        {this.props.product.name}
      </span>
    );
  }

  private renderName() {
    return (
      <h3 className={s.productName} data-hook={DataHook.Name}>
        {this.props.product.name}
      </h3>
    );
  }

  private renderPrice() {
    return <ProductPriceWithGlobalProps product={this.props.product} />;
  }

  private renderLineBetweenNameAndPrice() {
    return (
      <div>
        <hr data-hook={DataHook.LineBetweenNameAndPrice} className={s.productDivider} aria-hidden="true" />
      </div>
    );
  }

  private renderQuickViewButton() {
    return (
      <button
        className={s.quickViewButton}
        data-hook={DataHook.QuickViewButton}
        tabIndex={-1}
        aria-hidden="true"
        onClick={this.handleQuickViewButtonClick}>
        {this.props.globals.textsMap.quickViewButtonText}
      </button>
    );
  }

  private renderRibbon() {
    const {
      product: {ribbon},
    } = this.props;
    return (
      ribbon && (
        <span className={s.ribbon} data-hook={DataHook.Ribbon}>
          {ribbon}
        </span>
      )
    );
  }

  private handleQuickViewButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
    const {
      globals: {openQuickView},
      product: {id: productId},
      index,
    } = this.props;

    event.preventDefault();
    event.stopPropagation();
    openQuickView({productId, index});
  }

  private async handleAddToCartButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    event.stopPropagation();

    const {
      globals: {handleAddToCart},
      product: {id: productId},
      index,
    } = this.props;

    await handleAddToCart({productId, index});
  }

  private handleProductItemClick(event: React.MouseEvent<HTMLAnchorElement>) {
    const {
      globals: {handleProductItemClick},
      product: {id: productId},
      index,
      disabled,
    } = this.props;

    event.preventDefault();
    if (disabled) {
      return;
    }
    handleProductItemClick({
      biData: {
        productId,
        index,
      },
    });
  }

  private readonly isProductAddedToCartSuccessfully = prevAddedToCartSuccessfully => {
    const {
      globals: {addedToCartSuccessfully: currentAddedToCartSuccessfully, shouldShowAddToCartSuccessAnimation},
      product,
    } = this.props;

    const isProductAddedSuccessfully =
      prevAddedToCartSuccessfully &&
      currentAddedToCartSuccessfully &&
      currentAddedToCartSuccessfully[product.id] !== prevAddedToCartSuccessfully[product.id];

    return isProductAddedSuccessfully && shouldShowAddToCartSuccessAnimation;
  };

  public componentDidUpdate(prevProps: Readonly<IProductItemProps>): void {
    const {
      globals: {
        styleParams: {
          fonts: {
            gallery_hoverType: {value: previousHoverType},
          },
        },
        addedToCartSuccessfully: prevAddedToCartSuccessfully,
      },
    } = prevProps;
    const {
      globals: {
        styleParams: {
          fonts: {
            gallery_hoverType: {value: currentHoverType},
          },
        },
      },
    } = this.props;

    if (previousHoverType !== currentHoverType) {
      this.setState({showHoverPreview: true});

      this.debouncedStopHoverPreview();
    }

    if (this.isProductAddedToCartSuccessfully(prevAddedToCartSuccessfully)) {
      //tslint:disable-next-line no-floating-promises
      this.addToCartButtonRef.current.onProgressReset();
    }
  }

  public shouldRenderAddToCartButton = () => {
    const {
      globals: {
        experiments: {isAddToCartButtonEnabled},
        styleParams: {
          booleans: {gallery_showAddToCartButton: shouldShowAddToCartButton},
        },
      },
    } = this.props;

    return isAddToCartButtonEnabled && shouldShowAddToCartButton;
  };

  public renderAddToCartButton = () => {
    const {
      globals: {
        isMobile,
        textsMap,
        styleParams: {
          booleans: {gallery_addToCartButtonShowOnHover: shouldShowAddToCartButtonShowOnHover},
        },
      },
      product,
    } = this.props;

    const isOutOfStock = !product.isInStock;
    const showContactSeller = product.price === 0;
    const shouldDisable = isOutOfStock || showContactSeller;

    let buttonText = textsMap.galleryAddToCartButtonText;
    if (showContactSeller) {
      buttonText = textsMap['gallery.contactSeller.button'];
    } else if (isOutOfStock) {
      buttonText = textsMap['gallery.outOfStock.button'];
    }

    return (
      <StatesButton
        ref={this.addToCartButtonRef}
        text={buttonText}
        onClick={this.handleAddToCartButtonClick}
        disabled={shouldDisable}
        className={classNames(addToCartButtonStylable.addToCartButton, {
          [s.showOnHover]: shouldShowAddToCartButtonShowOnHover && !isMobile,
        })}
        fullWidth
        dataHook={DataHook.AddToCartButton}
      />
    );
  };

  public render() {
    const {
      globals: {
        isMobile,
        isLiveSiteMode,
        productIdToProductPageUrlMap,
        styleParams: {
          booleans: {
            gallery_showPrice: shouldShowPrice,
            gallery_showProductName: shouldShowName,
            gallery_showDividers: shouldShowLineBetweenNameAndPrice,
            showQuickView: shouldShowQuickViewButton,
          },
          fonts: {
            gallery_hoverType: {value: hoverType},
          },
          numbers: {galleryImageRatio: imageRatioId, gallery_imageMode: imageModeId},
        },
      },
      product,
      style,
    } = this.props;

    const {showHoverPreview} = this.state;

    const shouldShowProductDetails = shouldShowName || shouldShowLineBetweenNameAndPrice || shouldShowPrice;
    const productPageUrl = productIdToProductPageUrlMap[product.id];

    return (
      <a
        style={style}
        href={isLiveSiteMode ? productPageUrl : null}
        onClick={this.handleProductItemClick}
        className={classNames(s.productItem, isMobile ? s.none : s[hoverType], {[s.hoverPreview]: showHoverPreview})}
        data-hook={DataHook.Root}
        ref={this.productLink}>
        <ProductImage
          classNames={{thumbnail: s.productThumbnail, image: s.productImage}}
          product={product}
          isMobile={isMobile}
          hoverType={hoverType}
          imageRatioId={imageRatioId}
          imageModeId={imageModeId}>
          {this.renderRibbon()}
          {!isMobile && shouldShowQuickViewButton && this.renderQuickViewButton()}
        </ProductImage>
        {!shouldShowName && this.renderSrOnlyName()}
        {shouldShowProductDetails && (
          <div className={s.productDetails} data-hook={DataHook.ProductDetails}>
            {shouldShowName && this.renderName()}
            {shouldShowLineBetweenNameAndPrice && this.renderLineBetweenNameAndPrice()}
            {shouldShowPrice && this.renderPrice()}
          </div>
        )}
        {this.shouldRenderAddToCartButton() && this.renderAddToCartButton()}
      </a>
    );
  }
}
