import {IWixStyleParams} from '@wix/wixstores-client-core/dist/es/src/types/wix-sdk';
import {IControllerConfig, IStyle} from '@wix/native-components-infra/dist/src/types/types';
import {ISantaProps} from '@wix/wixstores-client-core/dist/es/src/types/native-types';
import {GalleryStore} from '../viewerScript/GalleryStore';
import {GetDataQuery, ProductFilters, ProductSort} from '../graphql/queries-schema';
import {IFilterValue} from '@wix/wixstores-graphql-schema';
import {CollectionFilterModel} from '../models/CollectionFilterModel';
import {CustomCollectionFilterModel} from '../models/CustomCollectionFilterModel';
import {ListFilterModel} from '../models/ListFilterModel';
import {ColorFilterModel} from '../models/ColorFilterModel';
import {PriceFilterModel} from '../models/PriceFilterModel';
import {AddToCartActionOption} from '@wix/wixstores-client-core/dist/es/src/constants';
import {GUID} from '@wix/bi-logger-ec-sf';

export interface IGalleryStyleParams extends IWixStyleParams {
  booleans: Partial<{
    responsive: boolean;
    full_width: boolean;
    galleryFiltersCategories: boolean;
    galleryFiltersPrice: boolean;
    galleryFiltersProductOptions: boolean;
    galleryShowFilters: boolean;
    galleryShowSort: boolean;
    gallerySortNameAsc: boolean;
    gallerySortNameDes: boolean;
    gallerySortNewest: boolean;
    gallerySortPriceAsc: boolean;
    gallerySortPriceDes: boolean;
    gallery_addToCartButtonShowOnHover: boolean;
    gallery_showAddToCartButton: boolean;
    gallery_showDividers: boolean;
    gallery_showPrice: boolean;
    gallery_showProductName: boolean;
    gallery_showTitle: boolean;
    gallery_paginationFirstLastArrows: boolean;
    showAlternativeImage: boolean;
    showQuickView: boolean;
  }>;
  numbers: Partial<{
    galleryColumns: number;
    galleryRows: number;
    galleryMargin: number;
    gallery_imageMode: ImageModeId;
    gallery_productMargin: number;
    gallery_addToCartAction: AddToCartActionOption;
    galleryImageRatio: ImageRatioId;
    ['mobile:galleryColumns']: number;
    ['mobile:galleryMargin']: number;
  }>;
  fonts?: Partial<{
    gallery_hoverType: {fontStyleParam: boolean; value: HoverType};
    gallery_loadMoreProductsType: {fontStyleParam: boolean; value: LoadMoreType};
    gallery_paginationFormat: {fontStyleParam: boolean; value: PaginationFormat};
    gallery_paginationAlignment: {fontStyleParam: boolean; value: ContentJustification};
  }>;
}

export interface IGalleryControllerConfig extends IControllerConfig {
  style: {
    styleParams: IGalleryStyleParams;
  };
}

export interface IGalleryStyle extends IStyle {
  styleParams: IGalleryStyleParams;
}

export interface IGallerySantaProps extends ISantaProps {
  style: IGalleryStyle;
}

export interface IPriceRangeValue {
  min: string;
  max: string;
}

export interface IRangeValue {
  min: number;
  max: number;
}

export interface ITextsMap {
  allCollectionsFilterButtonText: string;
  clearFiltersButtonText: string;
  filtersSubmitButtonText?: string;
  filtersTitleText: string;
  galleryRegionSR: string;
  loadMoreButtonText: string;
  mobileFiltersButtonText?: string;
  noProductsFilteredMessageText: string;
  noProductsMessageText: string;
  productOutOfStockText: string;
  productPriceAfterDiscountSR: string;
  productPriceBeforeDiscountSR: string;
  productPriceWhenThereIsNoDiscountSR: string;
  quickViewButtonText: string;
  sortOptionHighPriceText: string;
  sortOptionLowPriceText: string;
  sortOptionNameAZText: string;
  sortOptionNameZAText: string;
  sortOptionNewestText: string;
  sortTitleText: string;
  digitalProductBadgeAriaLabelText: string;
  galleryAddToCartButtonText: string;
}

export interface IPropsInjectedByViewerScript {
  clearFilters: Function;
  currentPage: number;
  cssBaseUrl: string;
  filterModels: FilterModel[];
  filterProducts(filterId: number, value: IFilterSelectionValue, fetchProducts?: boolean): void;
  filterProductsOnMobile: Function;
  focusedProductIndex?: number;
  getCategoryProducts?(params: {limit: number; offset?: number; total?: number}): void;
  handleProductItemClick: GalleryStore['handleProductItemClick'];
  handleMobileSortClick: Function;
  handleAddToCart(params: {productId: string; index: number}): Promise<void>;
  handlePagination(page: number): void;
  hasMoreProductsToLoad: boolean;
  hasSelectedFilters: boolean;
  isFirstPage: boolean;
  isInteractive: boolean;
  isLiveSiteMode: boolean;
  isLoaded: boolean;
  isMobile: boolean;
  isRTL: boolean;
  loadMoreProducts: Function;
  loadMoreType: LoadMoreType;
  loading: boolean;
  mainCollectionId: string;
  onAppLoaded: Function;
  openQuickView: GalleryStore['openQuickView'];
  productIdToProductPageUrlMap: {[key: string]: string};
  products: IProduct[];
  ravenUserContextOverrides: {id: string; uuid: GUID};
  setProductsPerPage: Function;
  shouldShowClearFilters: boolean;
  shouldShowMobileFiltersModal: boolean;
  shouldShowSort: boolean;
  showShowLightEmptyState: boolean;
  sortProducts: Function;
  textsMap: ITextsMap;
  toggleFiltersModalVisibility: Function;
  totalProducts: number;
  experiments: {
    isMobileSortEnabled: boolean;
    isFilterAndSortWithQueryParams: boolean;
    isAddToCartButtonEnabled: boolean;
  };
  numberOfSelectedFilterTypes: number;
  sortingOptions: ISortingOption[];
  selectedSort: ISortingOption;
  shouldShowAddToCartSuccessAnimation: boolean;
  addedToCartSuccessfully: {[productId: string]: number};
}

export interface ISortingOption {
  field: SortingOptionField;
  direction?: SortingDirection;
  id: SortingOptionId;
  titleKey: SortingOptionTitleKey;
  settingsShouldDisplayKey?: SortingOptionBooleanKey;
}

export type SortingOptionField = 'creationDate' | 'comparePrice' | 'name' | '';
export const enum SortingDirection {
  Ascending = 'ASC',
  Descending = 'DESC',
}

export type SortingOptionId =
  | 'default'
  | 'newest'
  | 'price_ascending'
  | 'price_descending'
  | 'name_ascending'
  | 'name_descending';

export type SortingOptionTitleKey =
  | 'sortOptionNameZAText'
  | 'sortOptionNameAZText'
  | 'sortOptionHighPriceText'
  | 'sortOptionLowPriceText'
  | 'sortOptionNewestText'
  | 'sortTitleText';

export type SortingOptionBooleanKey =
  | 'gallerySortNameDes'
  | 'gallerySortNameAsc'
  | 'gallerySortPriceDes'
  | 'gallerySortPriceAsc'
  | 'gallerySortNewest'
  | 'sortTitleText';

export type IProduct = GetDataQuery['catalog']['category']['productsWithMetaData']['list'][0];

export enum ProductType {
  DIGITAL = 'digital',
  PHYSICAL = 'physical',
}

export enum MediaFrameMediaType {
  SECURE_PICTURE = 'secure_picture',
  SECURE_VIDEO = 'secure_video',
  SECURE_DOCUMENT = 'secure_document',
  SECURE_MUSIC = 'secure_music',
  SECURE_ARCHIVE = 'secure_archive',
}

export interface IProductItemData {
  id: string;
  name: string;
  urlPart: string;
  price: number;
  comparePrice: number;
  formattedPrice: string;
  formattedComparePrice: string;
  ribbon: string;
  isOptionMandatory: boolean;
  media: IMediaData[];
  inventoryStatus: any;
  isInStock?: boolean;
  productType: ProductType;
  digitalProductFilesTypes: MediaFrameMediaType[];
}

export interface IMediaData extends IImageDimension {
  url: string;
  mediaType: string;
  index: number;
  title: string;
  altText: string;
}

export interface IImageDimension {
  width: number;
  height: number;
}

export type IFilterSelectionValue = string | IPriceRangeValue;

export interface IFilterModel {
  activeOptions: any;
  filterId: number;
  filterType: FilterType;
  title: string;
  toDTO?: Function;
  options: IFilterOption[];

  updateActiveOptions(value: IFilterSelectionValue): void;
  hasActiveOptions?(): boolean;
  resetActiveOptions(): void;
}

export type IFilterOption = IFilterValue;

export interface IFilterConfig {
  filterTitle: string;
  filterType: FilterConfigType;
  show: boolean;
  custom?: boolean;
  selected?: {[id: string]: string}[];
  id?: string;
}

export enum FilterType {
  COLLECTION = 'COLLECTION',
  CUSTOM_COLLECTION = 'CUSTOM_COLLECTION',
  PRICE = 'PRICE ',
  COLOR_OPTION = 'COLOR_OPTION',
  LIST_OPTION = 'LIST_OPTION',
}

export enum FilterTypeFromFetch {
  CATEGORY = 'CATEGORY',
  FILTERED_CATEGORIES = 'FILTERED_CATEGORIES',
  PRICE = 'PRICE',
  COLOR = 'OPTION_COLOR',
  LIST = 'OPTION_LIST',
}

export enum FilterTypeForFetch {
  CATEGORY = 'CATEGORY',
  FILTERED_CATEGORIES = 'FILTERED_CATEGORIES',
  OPTIONS = 'OPTIONS',
  PRICE = 'PRICE',
}

export enum FilterConfigType {
  CATEGORY = 'CATEGORY',
  PRICE = 'PRICE',
  OPTIONS = 'OPTIONS',
  OPTION_LIST = 'OPTION_LIST',
}

export type FilterModel =
  | CollectionFilterModel
  | CustomCollectionFilterModel
  | ListFilterModel
  | ColorFilterModel
  | PriceFilterModel;

export interface IFilterDTO {
  field: string;
  op: FilterEqOperation;
  values: any[];
}

export interface ICollectionIdsFilterDTO {
  mainCategory: string;
  subCategory?: string;
  customCategories?: string[][];
}

export interface IDeprecatedFilterConfigDTO {
  filterTitle: string;
  filterType: FilterConfigType;
  show: boolean;
  custom?: boolean;
  selected?: {[id: string]: string}[];
}

export interface IFilterConfigDTO {
  filterType: FilterConfigType;
  id: string;
  show: boolean;
  custom?: boolean;
  selected?: {[id: string]: string}[];
}

export enum FilterEqOperation {
  EQUALS = 'EQUALS',
  GTE = 'GTE', // Greater than or equal to
  LTE = 'LTE', // Less than or equal to
  BETWEEN = 'BETWEEN',
  IN = 'IN',
  IN_ALL = 'IN_ALL',
}

export interface IFilteredCategoryProductsViewResponse {
  data: {
    products: IProductItemData[];
    totalCount: number;
  };
  errors?: any[];
}

export type nonCollectionFilterModel = ListFilterModel | ColorFilterModel | PriceFilterModel;

export interface IColorOption {
  rgbValue: string;
  name: string;
}

export enum ImageModeId {
  Crop = 1,
  Fit = 2,
}

export enum HoverType {
  None = 'none',
  Zoom = 'zoom',
  Border = 'border',
  Alternate = 'alternate',
}

export enum ImageRatioId {
  _3x2 = 0,
  _4x3 = 1,
  _1x1 = 2,
  _3x4 = 3,
  _2x3 = 4,
  _16x9 = 5,
  _9x16 = 6,
}

export interface ISorting extends ISortingParam {
  id: string;
  titleKey: string;
}

export interface IAmountLimit {
  from: number;
  to: number;
}

export interface ISortingParam {
  field: string;
  direction?: SortingDirection;
}

export interface IFilteredProductsRequestParams {
  filters: IFilterDTO[];
  limit: IAmountLimit;
  sort?: ISortingParam;
  offset?: number;
  categories?: ICollectionIdsFilterDTO;
}

export interface IFilteredProductsRequest {
  viewName: string;
  params: IFilteredProductsRequestParams;
}

export interface ProductIdToProductPageUrlMap {
  [productId: string]: string;
}

export const enum GALLERY_TYPE {
  COLLECTION = 1,
  RELATED_PRODUCTS = 2,
}

export interface IOldGetInitialData {
  externalId: string;
  compId: string;
  limit?: number;
}

export interface IGetInitialData {
  externalId: string;
  compId: string;
  limit?: number;
  sort?: ProductSort;
  filters?: ProductFilters;
  offset?: number;
}

export interface IQueryParamsFilter {
  key: string;
  value: string;
  filterId: number;
}

export enum PaginationFormat {
  PAGES = 'pages',
  COMPACT = 'compact',
}

export enum LoadMoreType {
  PAGINATION = 'pagination',
  BUTTON = 'button',
}

export enum ContentJustification {
  START = 'start',
  CENTER = 'center',
  END = 'end',
}
